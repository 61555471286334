//import FilePieceArea from "@/pages/mobile/basic/file/piece/file";
import Style from "./css/style.module.css";
import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import plus_glass from "@/img/mobile/ico/plus_glass.png";
import LoadingComponent from "@/pcomponents/common/content/loading";
import ViewFileListArea from "./area/view_file_list";

const FilePieceArea = forwardRef((props: any, ref) => {
  let myProps = {
    file_accept_sort: "audio",//audio,video,img
    file_arr: [],
    default_file_row_data: {},
    user_data: {
      "iam_mcomp_seq": "",
      "iam_mcomp_user_seq": "",
      "comp_api_key": "",
    },
    callback: (inData: any) => { },
    ...props
  };
  let file_accept_sort = myProps.file_accept_sort;
  let file_accept = "*";
  let file_accept_text = "파일";
  if (file_accept_sort == "audio") {
    file_accept = "audio/*";
    file_accept_text = "녹음파일";
  }else if (file_accept_sort == "img") {
    file_accept = "image/*";
    file_accept_text = "사진";
  } else if (file_accept_sort == "video") {
    file_accept = "video/*";
    file_accept_text = "동영상";
  }
  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);
  let default_file_input_data = {
    "url": plus_glass,
    "name": "",
  };
  const [file_input_data, set_file_input_data] = useState(default_file_input_data);
  const [is_upload_ing, set_is_upload_ing] = useState(false);
  let file_arr: any = myProps.file_arr;

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if (!strFunc.is_empty(myProps.user_data.iam_mcomp_user_seq)) {
    iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
    comp_api_key = myProps.user_data.comp_api_key;
  }

  let default_file_row_data: any = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_comp_seq": "",
    "a_par_id": "home_work_file",
    "a_par_seq": "",
    "a_sort1": "audio",
    "a_sort2": "",
    "a_sort3": "",
    "a_writer": user.user_name,
    "a_create_seq": iam_mcomp_user_seq,
    ...myProps.default_file_row_data
  };

  useEffect(() => {
    set_is_upload_ing(false);
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    get_file_input, get_file_form,
    goWrite
  }));

  const get_file_input = () => {
    return fileInput.current;
  };

  const get_file_form = () => {
    return writeFileForm.current;
  };

  const goWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {

      },
      ...inData
    };
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    var form = writeFileForm.current;
    var form_json_data = new FormData();
    let file_row_data_arr: any = [];
    for (let i = 0; i < fileInput.current.files.length; i++) {
      let file_info = fileInput.current.files[i];
      form_json_data.append('file_arr[]', file_info);
      file_row_data_arr.push({ ...file_row_data });
    }
    form_json_data.append("data_arr", JSON.stringify(file_row_data_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    set_is_upload_ing(true);
    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data, get_axios_data())
      .then((response) => {
        set_is_upload_ing(false);
        if (response.data["result"] === "true") {
          remove_file_input_select();
          myProps.callback(response.data["data"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("파일을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    }, get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        myProps.callback();
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  const remove_file_input_select = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
    }
    set_file_input_data(default_file_input_data);
  };

  return (
    <div className={Style.file_area_wrap + " noto_sans"} >
      <h4 style={{textAlign:"right"}}>
        <label style={{ cursor: "pointer" }}>
          <div className={Style.file_write_wrap} >
            {file_accept_text}
            <img src={plus_glass} alt={plus_glass}
              style={{ cursor: "pointer", height: 23, marginLeft: 3 }} />
            <p>
              {file_input_data.name}
            </p>
            <form method="post" ref={writeFileForm} onSubmit={() => { return false; }} >
              <input type="file" className="input_file" name="input_file[]"
                ref={fileInput} hidden multiple
                accept={file_accept} onChange={(e: any) => {
                  if (e.target.files.length > 0) {
                    let file = e.target.files[0];
                    set_file_input_data({
                      url: URL.createObjectURL(file),
                      name: file.name
                    });
                    if (confirm(file_accept_text + "을 등록 하시겠습니까?")) {
                      goWrite({});
                    } else {
                      remove_file_input_select();
                    }
                  }
                }} />
            </form>
            {file_input_data.name != "" &&
              <span className="btn-s btn-red px-2"
                style={{ position: "absolute", right: 0, top: 0 }}
                onClick={() => {
                  remove_file_input_select();
                }}>x</span>
            }
          </div>
        </label>
      </h4>
      <div className={Style.file_list_wrap}>
        <div className={Style.file_form_wrap}>
          <ViewFileListArea
            file_arr={file_arr}
            default_file_row_data={default_file_row_data}
            Style={Style}
            goDelete={goDelete}
          ></ViewFileListArea>
        </div>
      </div>
      
      <LoadingComponent
        is_display={is_upload_ing}
        text="파일 업로드중.."
      ></LoadingComponent>
    </div>
  );
});
export default FilePieceArea;