import CardBoxArea from "./card_box";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import Style from "./css/style.module.css";
import { get_last_daily_report_by_month_prescribe_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";

function CardListArea(props: any) {
  let myProps = {
    infoArr: [],
    openOnePopup: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };
  let infoArr = myProps.infoArr;
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const get_card_box_tags = () => {
    let card_box_tags = infoArr.map((item: any, idx: number) => {
      let last_daily_report = get_last_daily_report_by_month_prescribe_info({
        row_data: item
      });
      return (
        <CardBoxArea key={idx}
          info={item}
          row_num={idx}
          select_stu_grade_option_arr={select_stu_grade_option_arr}
          Style={Style}
          list={myProps.list}
          openOnePopup={myProps.openOnePopup}
          last_daily_report={last_daily_report}
        ></CardBoxArea>
      );
    });
    return card_box_tags;
  };

  return (
    <div className={Style.card_list_wrap + " noto_sans"}>
      {get_card_box_tags()}
    </div>
  );
};
export default CardListArea;