//import ImgFileWriteList from "@/pages/mobile/manage/basic/file/frame/img/write_list";
import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import file_ico from "@/img/ico/parent_menu/par_notice.png";
import Style from "./css/style.module.css";
import popupFunc from "@/lib/lyg/popup";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewFileSlidePopup from "@/pages/mobile/manage/basic/file/popup/view_file_slide";
import strFunc from '@/lib/lyg/string';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LoadingComponent from "@/pcomponents/common/content/loading";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

const ImgFileWriteList = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_seq: "",
    select_mcomp_api_key: "",
    select_mcomp_user_seq: "",
    "is_view": false,
    "listOpt": {},
    "info_arr": [],
    "is_init_list": false,
    "is_save_btn": false,
    "is_select_save": false,
    "select_file_text": "사진선택",
    "on_file_change": (inData: any) => { },
    "callback_write": (inData: any) => { },
    "delete_callback": (inData: any) => { },
    ...props
  };
  let init_list_opt = {
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_par_id": "",
    "s_par_seq": "",
    "s_sort1": "",
    "s_sort2": "",
    "s_sort3": "",
    ...myProps.listOpt
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data = UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq: myProps.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_api_key,
    default_mcomp_user_seq: myProps.select_mcomp_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  const [listOpt, setListOpt] = useState({
    "now_page": 1,
    "num_per_page": 20,
    "order_id": "a_create_date DESC",
    "order_type": "",
    "s_start_date": "",
    "s_end_date": "",
    "is_add_idx_info": "1",
    "s_par_id": "",
    "s_par_seq": "",
    "s_sort1": "",
    "s_sort2": "",
    "s_sort3": "",
    ...init_list_opt
  });
  const fileInput = useRef<any>(null);
  const writeForm = useRef<HTMLFormElement>(null);
  const downloadFormRef = useRef<HTMLFormElement>(null);
  const [infoArr, setInfoArr] = useState(myProps.info_arr); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [is_view, set_is_view] = useState(myProps.is_view);
  const [refresh, set_refresh] = useState(false);
  const [loading_data, setLoadingData] = useState({
    is_display: false,
    text: "로딩중.."
  });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view_file_slide",//view_file_slide
    file_info_arr: [],
    select_file_idx: 0,
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    if (myProps.is_init_list == false) {
      list({});
    }
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, go_write, set_init_data, get_file_input_value
  }));

  const set_init_data = (inData: any) => {
    let opt_obj = {
      "is_view": myProps.is_view,
      "listOpt": init_list_opt,
      "info_arr": [],
      "is_init_list": false,
      ...inData
    };
    opt_obj.listOpt = {
      ...init_list_opt,
      ...opt_obj.listOpt
    };
    set_is_view(opt_obj.is_view);
    if (opt_obj["is_init_list"] == false) {
      list(opt_obj.listOpt);
    } else {
      setInfoArr(opt_obj.info_arr);
    }
  };

  const get_file_input_value = () => {
    if (fileInput.current) {
      return fileInput.current.value;
    } else {
      return "";
    }
  }

  const list = (inData: any) => {
    let list_form_data = {
      ...listOpt,
      ...inData
    };
    setListOpt(list_form_data);
    list_form_data["s_comp_seq"] = iam_mcomp_seq;
    if (strFunc.is_empty(list_form_data.s_comp_seq)
      || strFunc.is_empty(list_form_data.s_par_id)
      || strFunc.is_empty(list_form_data.s_par_seq)
      || list_form_data.s_par_seq == undefined) {
      setInfoArr([]);
      setCountInfo({ "tot": 0 });
      return false;
    }
    go_empty_file_input();
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const go_write = (inData: any) => {
    let optObj: any = {
      //"a_par_seq":""
      ...inData
    };
    let tmp_list_opt = {
      ...listOpt,
      ...optObj
    };
    setListOpt(tmp_list_opt);

    let tmpRowData = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": iam_mcomp_seq,
      "a_par_id": tmp_list_opt.s_par_id,
      "a_par_seq": tmp_list_opt.s_par_seq,
      "a_sort1": tmp_list_opt.s_sort1,
      "a_sort2": tmp_list_opt.s_sort2,
      "a_sort3": tmp_list_opt.s_sort3,
      "a_writer": user.user_name,
      "a_create_seq": iam_mcomp_user_seq,
    };
    if (tmpRowData.a_par_seq == "" || tmpRowData.a_par_id == "") {
      myProps.callback_write({ "result": "false", "msg": "파일 부모 정보가 올바르지 않습니다." });
      return false;
    }

    if (fileInput.current == null) {
      myProps.callback_write({ "result": "false", "msg": "파일이 없습니다." });
      return false;
    }
    if (writeForm.current == null) {
      myProps.callback_write({ "result": "false", "msg": "폼이 없습니다." });
      return false;
    }

    if (fileInput.current.value == "") {
      myProps.callback_write({ "result": "false", "msg": "파일 선택이 필요합니다." });
      return false;
    }

    let file_row_arr: any = [];
    for (let i = 0; i < fileInput.current.files.length; i++) {
      file_row_arr.push({
        ...tmpRowData
      });
    }

    var form = writeForm.current;
    var form_json_data = new FormData(form);
    form_json_data.append("data_arr", JSON.stringify(file_row_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    setLoadingData({
      is_display: true,
      text: "저장중."
    });
    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setLoadingData({
            is_display: true,
            text: "저장되었습니다."
          });
          setTimeout(() => {
            setLoadingData({
              is_display: false,
              text: ""
            });
          }, 1200);
          list({
            ...optObj,
            "now_page": "1",
          });
          myProps.callback_write(response.data["data"]);
          go_empty_file_input();
        } else {
          myProps.callback_write(response.data["data"]);
        }
      });
  };

  const go_empty_file_input = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
      for (let i = 0; i < fileInput.current.files.length; i++) {
        fileInput.current.files[i].value = "";
      }
      set_refresh(!refresh);
    }
  };

  const goDownLoad = (file_info: any) => {
    let pri_val = file_info["a_ymd"] + "," + file_info["a_seq"];
    if (downloadFormRef.current == null) {
      return false;
    }
    downloadFormRef.current.action = file_info["a_serverurl"] + "/api/file/download/" + pri_val;
    downloadFormRef.current.submit();
  };
  const goDeleteFile = (file_info_arr: any) => {
    let selected_row = file_info_arr;
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let del_row_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let file_info = selected_row[i];
      del_row_arr.push({
        "a_ymd": file_info["a_ymd"],
        "a_seq": file_info["a_seq"],
      });
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: del_row_arr,
    }, get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        setLoadingData({
          is_display: true,
          text: "삭제 되었습니다."
        });
        setTimeout(() => {
          setLoadingData({
            is_display: false,
            text: ""
          });
        }, 1200);
        list({});
        myProps.delete_callback({});
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const open_view_file_list_popup = (inData: any) => {
    let opt_obj = {
      file_info_arr: [],
      select_file_idx: 0,
      ...inData
    };

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "파일보기",
      "sort": "view_file_slide",
      "file_info_arr": opt_obj.file_info_arr,
      "select_file_idx": opt_obj.select_file_idx,
      width: "800px",
      height: "100%",
    });
  };

  const get_file_tags_by_file_arr = (file_arr: any[]) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let icon_img = file_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.thum_img_url;
        if (file_info.a_ext == "png") {
          icon_img = file_info.img_url;
        }
      }

      return (
        <div key={idx} className={Style.row_content_file_row}>
          <a style={{ cursor: "pointer" }} onClick={() => {
            open_view_file_list_popup({
              file_info_arr: file_arr,
              select_file_idx: idx
            });
          }} >
            <img src={icon_img} className={Style.row_content_file_row_thum} />
          </a>
          <p>
            {file_info.a_oriname}
            <button className="btn-s btn-gray ml-1" onClick={() => { goDownLoad(file_info); }}>다운</button>
            {is_view == false &&
              <button className="btn-s btn-red ml-1" onClick={() => { goDeleteFile([file_info]); }} >X</button>
            }
          </p>
        </div>
      );
    });

    return file_tags;
  };

  let file_tags: any = "";
  if (infoArr) {
    file_tags = get_file_tags_by_file_arr(infoArr);
  }

  return (
    <div className="mt-1">
      <form method="post" ref={writeForm} >
        <div className="text-center" style={{ display: is_view ? "none" : "" }} >
          <span className="btn-s btn-sky" onClick={(e: any) => {
            e.target.parentElement.querySelector(".input_file")?.click();
          }}  >
            {myProps.select_file_text}
            {(fileInput.current && fileInput.current.files.length > 0) &&
              "(" + fileInput.current.files.length + ")"
            }
          </span>
          <input type="file" className="input_file" name="input_file[]" ref={fileInput} multiple hidden
            onChange={(e: any) => {
              fileInput.current = e.target;
              if (fileInput.current.value != "") {
                myProps.on_file_change(e);
              }
              set_refresh(!refresh);
              if (myProps.is_select_save == true) {
                if (confirm("선택한 파일을 등록하시겠습니까?")) {
                  go_write({});
                }
              }
            }} accept="image/*" />
          {(fileInput.current != null && fileInput.current.files.length > 0) &&
            <span className="ml-1">
              <span className="btn-s btn-gray" onClick={() => {
                go_write({});
              }} >등록</span>
              <span className="btn-s btn-red ml-1" 
              style={{minWidth:"20px"}}
              onClick={() => {
                go_empty_file_input();
              }} >X</span>
            </span>
          }
        </div>
      </form>
      <div style={{ maxWidth: 500, margin: "0 auto",display:"flex",gap:"10px",overflow:"auto" }}>
        {file_tags}
      </div>
      <div style={{ maxWidth: 500, margin: "0 auto"}}>
        {infoArr.length >= listOpt.num_per_page &&
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
        }
      </div>
      <form ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
      </form>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} y={"0"} is_border_radius={false} >
          {popupData.sort === "view_file_slide" &&
            <ViewFileSlidePopup
              file_info_arr={popupData.file_info_arr}
              select_file_idx={popupData.select_file_idx}
              callback={(inData: any) => { list({ now_page: 1 }); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewFileSlidePopup>
          }
        </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
});
export default ImgFileWriteList;