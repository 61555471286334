function AttendArea(props: any) {
  let myProps = {
    info: {},
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    ...props
  };

  let info = myProps.info;

  let attend_in_info: any = null;
  if (info.attend_in_arr && info.attend_in_arr.length > 0) {
    attend_in_info = info.attend_in_arr[0];
  }
  let attend_out_info: any = null;
  if (info.attend_out_arr && info.attend_out_arr.length > 0) {
    attend_out_info = info.attend_out_arr[0];
  }
  let attend_color = "#707070";
  let attend_text_color="#fff";
  let attend_color_arr = [
    {"value":"attend","text":"등원",color:"#eab358"},
    {"value":"late","text":"등원(지각)",color:"#ea880b"},
    {"value":"absent","text":"결석",color:"#f26b6f"},
    {"value":"out","text":"하원",color:"#53a500"},
  ];
  if (attend_in_info != null) {
    for (let i = 0; i < attend_color_arr.length; i++) {
      if (attend_in_info["a_sort_detail"] == attend_color_arr[i]["value"]) {
        attend_color = attend_color_arr[i]["color"];
        if(attend_in_info["a_sort_detail"]=="attend"){
          attend_text_color="#000000";
        }
      }
    }
  }
  if (attend_out_info != null) {
    for (let i = 0; i < attend_color_arr.length; i++) {
      if (attend_out_info["a_sort_detail"] == attend_color_arr[i]["value"]) {
        attend_color = attend_color_arr[i]["color"];
        attend_text_color="#fff";
      }
    }
  }

  let attend_state_str = "미등원";
  let attend_memo = "";
  let attend_time = "00:00";
  let attend_out_time = "00:00";
  if (attend_in_info != null) {
    attend_memo = attend_in_info["a_memo"];
    attend_time = attend_in_info["a_time"].substring(0, 5);
    let a_sort_detail = attend_in_info["a_sort_detail"];

    if (attend_out_info != null) {
      attend_out_time = attend_out_info["a_time"].substring(0, 5);
      a_sort_detail = attend_out_info["a_sort_detail"];
    }
    for (let i = 0; i < attend_color_arr.length; i++) {
      let item = attend_color_arr[i];
      if (item.value == a_sort_detail) {
        attend_state_str = item.text;
      }
    }

    if (attend_state_str == "등원") {
      attend_state_str = "등원완료";
      attend_state_str = attend_time;
    }
  }

  const on_attend_click = () => {
    if (attend_in_info != null) {
      if (attend_out_info != null) {
        myProps.deleteAttend([attend_out_info], "하원 취소 하시겠습니까?");
      } else {
        myProps.deleteAttend([attend_in_info], "등원 취소 하시겠습니까?");
      }
    } else {
      myProps.openAttendPopup([info], "in", "attend");
    }
  };

  return (
    <span style={{ background: attend_color, color: attend_text_color }} >
      <input type="text" value={attend_state_str}
        readOnly
        onClick={on_attend_click}
        style={{ width: "60px", border: 0, textAlign: "center", cursor: "pointer", background: attend_color, color: attend_text_color }} />
    </span>
  );
};
export default AttendArea;