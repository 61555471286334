//import MobileScript from "@/lib/mobile/web_script";
class MobileScript {
  static toast(msg, len) {
    if (window.Web_Script && window.Web_Script.showToastMsg) {
      window.Web_Script.showToastMsg(msg, len);
    }else if(window.webkit){
      this.callIOSFunc({"method":"toast","msg":msg,"len":len});
    }
  }
  static callBackTest(msg) {
    if (window.Web_Script && window.Web_Script.callBackTest) {
      window.Web_Script.callBackTest(msg);
    }
  }
  static speechTTSMsg(msg) {
    if (window.Web_Script && window.Web_Script.speechTTSMsg) {
      window.Web_Script.speechTTSMsg(msg);
    }else if(window.webkit){
      this.callIOSFunc({"method":"speechTTSMsg","msg":msg});
    }
  }
  static go_to_webPage(url){
    if (window.Web_Script && window.Web_Script.go_to_webPage) {
      window.Web_Script.go_to_webPage(url);
    }else if(window.webkit){
      this.callIOSFunc({"method":"go_to_webPage","url":url});
    }else{
      window.open(url);
    }
  }
  static set_login_token(token) {
    if (window.Web_Script && window.Web_Script.set_login_token) {
      window.Web_Script.set_login_token(token);
    }else if(window.webkit){
      this.callIOSFunc({"method":"set_login_token","token":token});
    }
  }
  static get_login_token() {
    if (window.Web_Script && window.Web_Script.get_login_token) {
      return window.Web_Script.get_login_token();
    }else if(window.webkit){
      this.callIOSFunc({"method":"get_login_token"});
    }
    return "";
  }
  static get_fcm_token() {
    if (window.Web_Script && window.Web_Script.get_fcm_token) {
      return window.Web_Script.get_fcm_token();
    }else if(window.webkit){
      this.callIOSFunc({"method":"get_fcm_token"});
    }
    return "";
  }
  static checkMobile() {
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    if (varUA.indexOf('android') > -1) {
      //안드로이드
      return "android";
    } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
      //IOS
      return "ios";
    } else {
      //아이폰, 안드로이드 외
      return "other";
    }
  }
  static checkMobileApp(){
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    if(window.Web_Script&&varUA.indexOf('android')){
      return "android";
    }else if((varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1)&&window.webkit){
      return "ios";
    }else{
      return "other";
    }
  }
  static finishApp() {
    if (window.Web_Script && window.Web_Script.finishApp) {
      window.Web_Script.finishApp();
    }else if(window.webkit){
      this.callIOSFunc({"method":"finishApp"});
    }
  }
  static open_attend_activity(api_key, user_seq, comp_name) {
    if (window.Web_Script && window.Web_Script.open_attend_activity) {
      window.Web_Script.open_attend_activity(api_key, user_seq, comp_name);
    }
  }

  static get_version_str(){
    let version_name="";
    if (window.Web_Script && window.Web_Script.get_version_str) {
      version_name=window.Web_Script.get_version_str();
    }else if(window.webkit){
      this.callIOSFunc({"method":"get_version_str"});
    }
    return version_name;
  }

  static set_keep_screen_on(state_str){
    if (window.Web_Script && window.Web_Script.set_keep_screen_on) {
      window.Web_Script.set_keep_screen_on(state_str);
    }else if(window.webkit){
      this.callIOSFunc({"method":"set_keep_screen_on","state_str":state_str});
    }
  }

  static get_keep_screen_on(){
    let keep_screen_str="";
    if (window.Web_Script && window.Web_Script.get_keep_screen_on) {
      keep_screen_str=window.Web_Script.get_keep_screen_on();
    }else if(window.webkit){
      this.callIOSFunc({"method":"get_keep_screen_on"});
    }
    return keep_screen_str;
  }

  static set_screen_orientation(ori){
    //landscape,portrait
    if (window.Web_Script && window.Web_Script.set_screen_orientation) {
      keep_screen_str=window.Web_Script.set_screen_orientation(ori);
    }else if(window.webkit){
      this.callIOSFunc({"method":"set_screen_orientation","ori":ori});
    }
  }

  static open_outside_web(url){
    if (window.Web_Script && window.Web_Script.open_outside_web) {
      window.Web_Script.open_outside_web(url);
      return false;
    }else if(window.webkit){
      //this.callIOSFunc({"method":"open_outside_web","url":url});
      window.open(url);
      return false;
    }
    window.open(url);
  }

  static go_store_for_recent_version(){
    if (window.Web_Script && window.Web_Script.go_store_for_recent_version) {
      window.Web_Script.go_store_for_recent_version();
    }
  }

  static compareVersions(recent_version, now_version){
    const v1Parts = recent_version.split('.').map(Number);
    const v2Parts = now_version.split('.').map(Number);

    for (let i = 0; i < v1Parts.length; i++) {
      if(v2Parts[i]==undefined){
        return 1;
      }
      if (v1Parts[i] > v2Parts[i]) {
        return 1;
      } else if (v1Parts[i] < v2Parts[i]) {
        return -1;
      }
    }
    //1:최신버전 있음, 0:같은버전,-1:지금버전이 더큼
    //사용시 1이면 최신버전 받으러 가면됨

    // 사용 예제

    // console.log(compareVersions("1.2.3", "1.2.3.1"));
    // console.log(compareVersions("1.2.3", "1.2.4"));
    // console.log(compareVersions("1.4.3", "1.2.3"));
    
    return 0;
  }

  //callIOSFunc({"method":"","uid":""});
  static callIOSFunc(in_opt_obj) {
    var opt_obj = {
      "method": ""
    };
    for (var key in in_opt_obj) {
      opt_obj[key] = in_opt_obj[key];
    }
    var request_json_str = JSON.stringify(opt_obj);
    if(window.webkit){
      window.webkit.messageHandlers.CustomMethod.postMessage(request_json_str);
    }else{
      alert("지원되지 않는 기능입니다.");
    }
  }
}
export default MobileScript;